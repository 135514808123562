:root {
  --primaryClr: #3a4655;
  --secondaryClr: #2e3a4a;
  --trClr: #171d25;
}
body {
  margin: 0;
  font-size: 1.1rem;
}
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.wrapper {
  min-height: 100vh;
  box-shadow: 0 8px 50px -7px black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calculator {
  background: #3a4655;
  width: 400px;
  display: grid;
  padding: 0.2rem;
  gap: 0.2rem;
  grid-template-areas:
    "display display display display"
    "ac c perc divide"
    "b7 b8 b9 x"
    "b4 b5 b6 minus"
    "b1 b2 b3 plus"
    "b0 dot equals equals";
}
.calculator div {
  height: 70px;
  background: var(--secondaryClr);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.display {
  grid-area: display;
  justify-content: flex-end !important;
  padding-right: 10px;
  font-weight: bold;
}
.btn-ac {
  grid-area: ac;
  font-weight: bold;
  color: #ffa600 !important;
}
.btn-c {
  grid-area: c;
  font-weight: bold;
  color: #ffa600 !important;
}
.btn-equals {
  grid-area: equals;
  color: #a863f8 !important;
  font-weight: bold;
  font-size: 2rem;
}
.btn-perc {
  grid-area: perc;
}
.btn-divide {
  grid-area: divide;
}
.btn-7 {
  grid-area: b7;
}
.btn-8 {
  grid-area: b8;
}
.btn-9 {
  grid-area: b9;
}
.btn-x {
  grid-area: x;
}
.btn-4 {
  grid-area: b4;
}
.btn-5 {
  grid-area: b5;
}
.btn-6 {
  grid-area: b6;
}
.btn-minus {
  grid-area: minus;
}
.btn-1 {
  grid-area: b1;
}
.btn-2 {
  grid-area: b2;
}
.btn-3 {
  grid-area: b3;
}
.btn-plus {
  grid-area: plus;
}
.btn-0 {
  grid-area: b0;
}
.btn-dot {
  grid-area: dot;
}
.calculator :not(:first-child):hover {
  cursor: pointer;
  background: black;
  transform: 1.2s;
}
